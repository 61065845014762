/**
 * Download offline app landing page
 */

import React, { Fragment } from 'react';
import get from 'lodash/get';
import { Link } from 'gatsby';
import { Button } from '@components';
import styled from 'styled-components';
import { getUser } from '../services/auth';
const donwloadUrl = process.env.GATSBY_OFFLINE_APP_DOWNLOAD_URL;
const token = get(getUser(), 'token', '');

const Download = () => {
  return (
    <Fragment>
      <BackHomeLink element={Link} to="/">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" data-test-icon-element="true" focusable="false"><path d="M28.2 14.5h-20l6.2-6.2c.8-.8.8-2 0-2.8s-2-.8-2.8 0l-9.4 9.6c-.8.8-.8 2 0 2.8l9.6 9.6c.8.8 2 .8 2.8 0s.8-2 0-2.8l-6.2-6.2h20c1.2 0 2-.8 2-2s-1-2-2.2-2z"></path></svg>
        Go home
      </BackHomeLink>
      <Wrapper>
        <Headline>Choose operating system</Headline>
        <Buttons>
          <DownloadButton target="_blank" element="a" href={`${donwloadUrl}?os=mac&token=${token}`}>
            Mac
          </DownloadButton>
          <DownloadButton target="_blank" element="a" href={`${donwloadUrl}?os=windows&token=${token}`}>
            Windows
          </DownloadButton>
        </Buttons>
      </Wrapper>
    </Fragment>
  )
}

export default Download;

const Headline = styled.h1`
  font-size: 3rem;
  margin-bottom: 4rem;
  text-align: center;
`

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const DownloadButton = styled(Button)`
  font-size: 2rem;
  border: 2px solid #FFF;
  border-radius: 1rem;
  width: 20rem;
  text-align: center;
  margin: 1rem;
`

const BackHomeLink = styled(Button)`
  position: fixed;
  left: 1rem;
  top: 1rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    fill: #FFF;
    height: 1rem;
  }
`